import * as React from "react";
import { Row, Col, Card, Divider, Typography, Button, Tooltip } from "antd";
import {
  FacebookFilled,
  RedditOutlined,
  TwitterOutlined,
  YoutubeFilled,
  InstagramFilled,
  MailOutlined,
  LinkedinFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Link } from "gatsby";
import HeaderComp from "../components/header";
import "../components/layout.css";
import FooterComp from "../components/footer";

const Blog = () => {
  return (
    <>
      <HeaderComp />
      <div className="enterpriseBanner">
        <Row className="blogTitleRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h1 className="enterpriseTitle">
              Top Three Trends that Will Shape the Future of ERP Business
            </h1>
          </Col>
        </Row>
      </div>
      <div className="blogSubDiv">
        <p>
          <em>
            Businesses today are required to deal with technological
            advancements occurring at a pace never experienced before. With the
            advancement in high-tech innovations, embracing improved
            technologies are a must.{" "}
            <span className="order-management">
              <Link to="/order-management" className="blogLink">
                <strong>
                  <em>Enterprise Resource Planning</em>
                </strong>
              </Link>
            </span>{" "}
            (ERP) in particular has a lot to gain from adopting an open approach
            to new innovations.
          </em>
        </p>
        <p>
          <em>
            Listing down top three trends that will matter most in the ERP
            industry in the next few years-
          </em>
        </p>
        <ol className="BlogListOl">
          <li style={{ listStyleType: "decimal" }}>
            <strong>Cloud Stimulation: </strong>
            <span style={{ color: "red" }}>The most engaging game-changer</span>
          </li>
          <p>
            <em>
              Cloud-based ERP Software incorporates the use of Cloud computing
              platforms and services that offers an easy way to store your data.
            </em>
          </p>
          <p>
            <em>
              Previously, on-premise ERP solutions proved to be expensive and
              had hardware limitations and inflexibility. With the emergence of
              cloud-based ERP, small & medium scale industries benefited as
              there were no constraints regarding system expense, upgrades, or
              security.
            </em>
          </p>
          <li style={{ listStyleType: "decimal" }}>
            <strong>Data Analytics: </strong>
            <span style={{ color: "red" }}>Nothing beats numbers</span>
          </li>
          <p>
            <em>
              Data and analytics help owners and managers understand the
              dynamics of their business. With a paradigm shift from
              conventional- manual paper-based methods to the use of
              Internet-based techniques, a surge is seen in business profits.
              This practice has helped organizations increase bounce rates to
              their websites, more demographic reach, and enhanced knowledge
              about client behavior.
            </em>
          </p>
          <li style={{ listStyleType: "decimal" }}>
            <strong>Mobile Application: </strong>
            <span style={{ color: "red" }}>Always on the go</span>
          </li>
          <p>
            <em>
              Comprehensive mobile support is one of the basic functionalities
              of a modern ERP tool. Apart from on-the-go access to data, the
              mobile approach in ERP fetches a plethora of benefits like easy
              accessibility, accountability, call-transition support, better
              accuracy, and a lot more.
            </em>
          </p>
          <p>
            <em>
              Thus by the ongoing trends, it can be very well concluded that
              tying a solution to a particular system is a big mistake, instead
              of keeping your business up gradated with key features like mobile
              application will help minimize the consequences of production
              delays.
            </em>
          </p>
        </ol>
        <p style={{ paddingTop: 50 }}>
          <em>
            ERP systems with sharp & quick solutions manifest to grant profound
            and real-time data of core business processes. Thus, encouraging
            organizations to find reliable ERP implementations that not only
            provide information but also aid in operations, sales, data along
            with expanding human capabilities; is our prime motto.
          </em>
        </p>
        <Divider style={{ margin: "10px 0px" }} />
        <p style={{ marginBottom: 0, fontSize: "14px" }}>May 7th, 2020</p>
        <Divider style={{ margin: "10px 0px" }} />
        <Row className="platformRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h4 className="platformRowText">
              Share This Story, Choose Your Platform!
            </h4>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Row className="socialIconsRow">
              <div className="socialIconDiv">
                <Link
                  to="https://www.facebook.com/cwerpsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Facebook">
                    <FacebookFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://twitter.com/CwerpSolutions" target="_blank">
                  <Tooltip title="Twitter">
                    <TwitterOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Linkedin">
                    <LinkedinFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Reddit">
                    <RedditOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="WhatsApp">
                    <WhatsAppOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.youtube.com/channel/UC0wLykv1AkMd1ecUjRjbtwA"
                  target="_blank"
                >
                  <Tooltip title="Youtube">
                    <YoutubeFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Instagram">
                    <InstagramFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Email">
                    <MailOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
        <h3 className="platformRowText" style={{ paddingTop: 50 }}>
          Related Posts
        </h3>
        <Row>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
          >
            <Link to="/order-management-an-important-tool-for-your-e-commerce-business">
              <img src="https://cw.solutions/wp-content/uploads/2020/06/Order-Management.jpg" />
            </Link>
            <h2 className="orderManagementText">
              <Link
                to="/top-three-trends-that-will-shape-the-future-of-erp-business"
                className="orderManagementLink"
              >
                Order Management: An Important Tool for Your E-Commerce Business
              </Link>
            </h2>
            <p className="blogDate">
              June 2nd, 2020 |{" "}
              <span className="blogDate">
                <Link
                  to="/order-management-an-important-tool-for-your-e-commerce-business"
                  className="orderManagementLink"
                >
                  0 Comments
                </Link>
              </span>
            </p>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
            xxl={{ span: 18 }}
          />
        </Row>
      </div>
      <FooterComp />
    </>
  );
};

export default Blog;
